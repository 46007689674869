import React, { useEffect, useState } from "react";
import db, { auth } from "../Helpers/Firebase";
import Header from "../Helpers/Header";
import "./Members.css";
import { useNavigate } from "react-router-dom";
import useVGSnackBar from "../Helpers/SnackBar";

function Members() {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [allFilteredUsers, setFilteredAllUsers] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [searchText, setSearchText] = useState("");
  const [openSnackBar, closeSnackBar] = useVGSnackBar();

  //   useEffect(() => {
  //     auth.onAuthStateChanged((user) => {
  //       if (user) {
  //       } else {
  //         navigate("/");
  //       }
  //     });
  //   }, []);

  //   useEffect(() => {
  //     const starttime = new Date().getTime();
  //     const starttimestamp = Math.floor(starttime / 1000);
  //     return () => {
  //       const endtime = new Date().getTime();
  //       const endtimestamp = Math.floor(endtime / 1000);
  //       const difference = endtimestamp - starttimestamp;
  //       db.collection("analytics")
  //         .doc(auth?.currentUser?.uid || localStorage.getItem("_userid"))
  //         .update({
  //           members: firebase.firestore.FieldValue.increment(difference),
  //         });
  //     };
  //   }, []);

  useEffect(() => {
    db.collection("users")
      .orderBy("createdon", "desc")
      .get()
      .then((allusers) => {
        let userinfo = allusers.docs.map((each) => {
          return { id: each.id, ...each.data() };
        });
        let cityListVar = [];
        userinfo.forEach((each) => {
          if (
            each &&
            !cityListVar.includes(each?.city?.toUpperCase()) &&
            each !== ""
          ) {
            cityListVar.push(each?.city?.toUpperCase());
          }
        });
        setCityList(cityListVar);
        setAllUsers(userinfo);
      });
  }, []);

  const searchtextchanged = (e) => {
    var text = e.target.value;
    setSearchText(text);
    // applySearch();
  };

  const cityChanged = (e) => {
    var text = e.target.value;
    setSelectedCity(text);
    // applySearch();
  };

  const applySearch = () => {
    if (searchText === "" && selectedCity === "-") {
      openSnackBar("Please enter Name or City");
    } else {
      var finalhold = [];
      var tmphold = [];
      if (searchText === "") {
        tmphold = [...allUsers];
      } else {
        allUsers.forEach((eachuser) => {
          if (
            eachuser.email?.toLowerCase().includes(searchText.toLowerCase()) ||
            eachuser.firstname
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            eachuser.lastname?.toLowerCase().includes(searchText.toLowerCase())
          ) {
            tmphold.push(eachuser);
          }
        });
      }

      if (selectedCity === "") {
        setFilteredAllUsers(tmphold);
      } else {
        tmphold.forEach((eachuser) => {
          if (
            eachuser?.city?.toLowerCase().includes(selectedCity?.toLowerCase())
          ) {
            finalhold.push(eachuser);
          }
        });
        setFilteredAllUsers(finalhold);
      }
      if (searchText === "" && selectedCity === "") {
        setFilteredAllUsers([]);
      }
    }
  };

  return (
    <div className="members">
      {/* <Header /> */}
      <div className="memberslist">
        <div className="inputs">
          <input
            type="text"
            placeholder="Search by name or email"
            onChange={searchtextchanged}
          />
          <input
            type="text"
            placeholder="Search by city"
            onChange={cityChanged}
          />
          {/* <select onChange={(e) => setSelectedCity(e.target.value)}>
            <option value={"-"}>Select City</option>
            {cityList?.map((each) => {
              return <option>{each}</option>;
            })}
          </select> */}
          <button onClick={applySearch}>Search</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Email</th>
              <th>Mobile</th> */}
              <th>ARN</th>
              {/* <th>AUM</th> */}
              <th>City</th>
              {/* <th>Public Profile</th> */}
            </tr>
          </thead>
          {allFilteredUsers?.length === 0 && (
            <h4>
              No Matching Users Found !!! Please enter name of the user and
              select city to find
            </h4>
          )}
          <tbody>
            {allFilteredUsers?.map((each) => {
              return (
                <tr>
                  <td>
                    <a
                      target="_blank"
                      href={`https://members.volatilitygame.com/userProfile/${each?.id}_${each?.firstname}_${each?.lastname}`}
                      rel="noreferrer"
                    >
                      {each?.firstname} {each.lastname}
                    </a>
                  </td>
                  {/* <td>{each?.email}</td>
                  <td>{each?.mobile}</td> */}
                  <td>{each?.arn}</td>
                  {/* <td>{each?.aum}</td> */}
                  <td>{each?.city?.toUpperCase()}</td>
                  {/* <td>
                    <a
                      target="_blank"
                      href={`https://members.volatilitygame.com/userProfile/${each?.id}_${each?.firstname}_${each?.lastname}`}
                      rel="noreferrer"
                    >
                      View
                    </a>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Members;
