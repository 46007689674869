import React, { useState, useEffect } from "react";
import Header from "../Helpers/Header";
import "./Extras.css";
import { useNavigate } from "react-router-dom";
import firebase from "firebase";
import db, { auth } from "../Helpers/Firebase";

function Extras() {
  const navigate = useNavigate();

  const Calendar =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fcalendar.svg?alt=media&token=c97e0ddf-65c2-48c2-b978-13cf686e375e";
  const Coin =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fcoin.svg?alt=media&token=54af2ec3-bb6f-457d-9f95-16baa01d1857";
  const Division =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fcoin.svg?alt=media&token=54af2ec3-bb6f-457d-9f95-16baa01d1857";
  const Game =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fgame.svg?alt=media&token=2447881e-021d-4638-b7a6-1914d9e25c79";
  const Video =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fvideo.svg?alt=media&token=02af3101-7e51-4673-b05f-2833716d9e7b";
  const Ppt =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fppt.svg?alt=media&token=d2919f26-a122-4456-bbd0-68c09f469c1a";
  const Fire =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Ffire.svg?alt=media&token=cb2674c6-48fe-426c-bf6c-406e71399f1a";
  const Dollar =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fdollar.svg?alt=media&token=912219d8-9254-467c-a145-bad487ae9f75";
  const Calculator =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fcalculator.svg?alt=media&token=bd615a5c-f042-4765-a0df-2518a30ec3e1";
  const People =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fpeople.svg?alt=media&token=c287a4d1-0f8e-40d2-b99a-e738ead6b1b8";
  const Info =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Finfo.svg?alt=media&token=a3267fee-7b5c-46f9-b249-1da1c5d03969";

  const [contents, setcontents] = useState([
    { title: "Monthly Review", action: "/monthlyreview2", icon: Calendar },
    { title: "Mutual Funds Pick", action: "/mutualfundspick", icon: Coin },
    { title: "Understand Ratios", icon: Info, action: "/resources" },
  ]);

  useEffect(() => {
    const starttime = new Date().getTime();
    const starttimestamp = Math.floor(starttime / 1000);
    return () => {
      const endtime = new Date().getTime();
      const endtimestamp = Math.floor(endtime / 1000);
      const difference = endtimestamp - starttimestamp;
      db.collection("analytics")
        .doc(auth?.currentUser?.uid || localStorage.getItem("_userid"))
        .update({
          paidcourses: firebase.firestore.FieldValue.increment(difference),
        });
    };
  }, []);

  return (
    <div className="others">
      <Header />
      <div className="downloads">
        {contents.map((eachitem) => {
          return (
            <div
              className="eachdashboarditem"
              onClick={() => navigate(eachitem.action)}
            >
              <div className="content">
                <div className="allcontent">
                  <img src={eachitem.icon} width={80} alt="icon" />
                  <h4>{eachitem.title}</h4>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Extras;
