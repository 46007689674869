import React, { useEffect } from "react";
import Header from "../Helpers/Header";
import "./ExcelCalculators.css";
import firebase from "firebase";
import db, { auth } from "../Helpers/Firebase";

function ExcelCalculators() {
  const PPTICON =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fppt.svg?alt=media&token=d2919f26-a122-4456-bbd0-68c09f469c1a";
  const data = [
    {
      title: "Monthly Income & Expense Tracker",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FMonthly%20Income%20%26%20Expense%20Tracker.xlsx?alt=media&token=40afcf3e-3f5b-4799-9b10-266ec793fb7c",
    },
    {
      title: "Emergency Fund Tracker",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FEmergency%20Fund%20Tracker.xlsx?alt=media&token=2be8c0e6-fbb4-4c21-b1e5-2e3b0a246577",
    },
    {
      title: "Recurring Payment Tracker",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FRecurring%20Payment%20Tracker.xlsx?alt=media&token=51325f12-e153-4d1d-a1e2-e9c5a7c98385",
    },
    {
      title: "1% impact calculator",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2F1%25%20impact%20calculator.xlsx?alt=media&token=3688e40c-2499-4b17-916d-d9ea34e504dd",
    },
    {
      title: "Tax Estimator",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FTax%20Estimator.xlsx?alt=media&token=349b030a-1e6c-43d0-ae8d-2597c12c51a6",
    },
    {
      title: "Investment Portfolio Tracker",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FInvestment%20Portfolio%20Tracker.xlsx?alt=media&token=5294cdc5-fbd2-4a63-af37-e35137b4b3f9",
    },
    {
      title: "SIP & SWP Calculator",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FSIP%20%26%20SWP%20Calculator.xlsx?alt=media&token=6ed436b1-bf38-4092-ae3d-575c4f6d0e2f",
    },
    {
      title: "Cost of Delay Calculator",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FCost%20of%20Delay%20Calculator.xlsx?alt=media&token=780aae92-270e-4354-885c-f7b5ed192162",
    },
    {
      title: "Family Budgeting Planner",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FFamily%20Budgeting%20Planner.xlsx?alt=media&token=a45c78ee-e1d3-40d4-b586-f3d4ba2c78da",
    },
    {
      title: "Annual Budget Planner",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FAnnual%20Budget%20Planner.xlsx?alt=media&token=36125283-58b3-4804-96ec-9aaa7ba3280e",
    },
    {
      title: "Retirement",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FRetirement.xlsx?alt=media&token=807126f7-fb66-4954-a8bb-d5a98f72bd88",
    },
    {
      title: "Assets, Liabilities & Net Worth Statement",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FAssets%2C%20Liabilities%20%26%20Net%20Worth%20Statement.xlsx?alt=media&token=84c8cef9-86f9-4f26-9b13-7c83ad800fd2",
    },
    {
      title: "Cost of Delay Calculator",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FCost%20of%20Delay%20Calculator.xlsx?alt=media&token=780aae92-270e-4354-885c-f7b5ed192162",
    },

    {
      title: "Debt-to-Income Ratio Calculator",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FDebt-to-Income%20Ratio%20Calculator.xlsx?alt=media&token=6b885f7b-90f2-48db-9881-4132ad7c736f",
    },
    {
      title: "EMI Calculator",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FEMI%20Calculator.xlsx?alt=media&token=093f2a26-bb4d-4b2e-80d7-aa38ba9029ad",
    },
    {
      title: "Savings Goal Tracker",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FSavings%20Goal%20Tracker.xlsx?alt=media&token=0bd4b8b5-3702-4221-a7af-907f902528c9",
    },
    {
      title: "Tax Calculator",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FTax%20Calculator.xlsx?alt=media&token=c563c189-54a3-472a-b0b9-518026ac2ab6",
    },
    {
      title: "Crypto Investment Tracker",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FCrypto%20Investment%20Tracker.xlsx?alt=media&token=aa316d98-19d4-4fef-b44d-166850174353",
    },
    {
      title: "Power of Compounding",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FPower%20of%20Compounding.xlsx?alt=media&token=fb0f5b92-db15-4d15-8c11-001e1a12f0d2",
    },
    {
      title: "Debt Repayment Plan",
      url: "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/excel%20calculators%2FDebt%20Repayment%20Plan.xlsx?alt=media&token=e7d19f2b-0c82-4a3e-828d-12cf41797635",
    },
  ];
  useEffect(() => {
    const starttime = new Date().getTime();
    const starttimestamp = Math.floor(starttime / 1000);
    return () => {
      const endtime = new Date().getTime();
      const endtimestamp = Math.floor(endtime / 1000);
      const difference = endtimestamp - starttimestamp;
      db.collection("analytics")
        .doc(auth?.currentUser?.uid || localStorage.getItem("_userid"))
        .update({
          excelcalculators: firebase.firestore.FieldValue.increment(difference),
        });
    };
  }, []);
  return (
    <div className="excelcalculators">
      <Header />
      <div className="classroomcontent">
        <h4>Excel Calculators</h4>
        <div className="rvcccontent">
          {data?.map((each) => {
            return (
              <div className="eachvideo">
                <a target="_blank" rel="noreferrer" href={each.url} download>
                  <img src={PPTICON} alt="icon" />
                  {each.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ExcelCalculators;
