import React, { useEffect, useState } from "react";
import "./Header.css";
import db, { auth } from "./Firebase";
import { useNavigate, useParams } from "react-router-dom";
import Profile from "./Profile";

function Header() {
  const Logo =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fvgnewlogo.png?alt=media&token=fd0fd436-1706-46dd-ad8e-3282250de767";

  const navigate = useNavigate();
  const [userLogo, setUserLogo] = useState(null);

  const [userid, setuserid] = useState(null);
  const [user, setuser] = useState(null);
  const [disappearmodal, setdisappearmodal] = useState(true);
  useEffect(async () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        var uid = user.uid;
        setuserid(uid);
        db.collection("users")
          .doc(uid)
          .get()
          .then((userinfo) => {
            if (userinfo.data() === undefined) {
              setdisappearmodal(false);
            } else if (
              userinfo.data().isprofileverified === true ||
              userinfo.data().isprofileverified === false
            ) {
              setuser(userinfo.data());
              // setdisappearmodal(userinfo.data().isprofileverified);
            } else {
              setuser(userinfo.data());

              // setdisappearmodal(false);
            }
            if (
              userinfo.data().logourl !== undefined &&
              userinfo.data().logourl !== ""
            ) {
              setUserLogo(userinfo.data().logourl);
            }
          });
      } else {
      }
    });
  }, []);

  const logouttapped = () => {
    auth.signOut().then(() => {
      // localStorage.removeItem("_userid");
      navigate("/volatilitydashboard");
    });
  };

  return (
    <div className="header">
      <img src={Logo} width={200} />
      {user && disappearmodal === false && (
        <Profile
          setdisappearmodal={setdisappearmodal}
          forced={!(user.isprofileverified === true)}
          setuser={setuser}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        {user === null && userid !== null && disappearmodal === false && (
          <Profile
            setdisappearmodal={setdisappearmodal}
            forced={userid && !user}
            setuser={setuser}
          />
        )}
        {user && (
          <div className="profile">
            <div className="layer">
              <div>
                <h3
                  style={{
                    marginLeft: 0,
                    marginTop: 0,
                    marginBottom: 5,
                    padding: 0,
                  }}
                >
                  Hi {user.firstname}
                </h3>
                <h4
                  style={{
                    marginLeft: 0,
                    marginTop: 0,
                    fontSize: "0.8rem",
                    marginBottom: 0,
                    paddingTop: 0,
                    color: "grey",
                    paddingLeft: 0,
                  }}
                >
                  {user.usertype === "(FFF Pro Trial)"
                    ? "FFF Pro"
                    : "(" + user.usertype + ")"}
                </h4>
              </div>
              {userLogo && (
                <button
                  class="onlydesktop"
                  style={{
                    padding: 0,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  id="profile"
                  onClick={() => setdisappearmodal(false)}
                >
                  <img src={userLogo} width={40} height={40} />
                </button>
              )}
              {!userLogo && (
                <button
                  class="onlydesktop"
                  id="profile"
                  onClick={() => setdisappearmodal(false)}
                >
                  {user?.firstname?.[0]}
                </button>
              )}
            </div>
            <div className="layer">
              <button onClick={() => navigate("/vgdashboard")}>
                Dashboard
              </button>
              <button className="logoutbtn" onClick={logouttapped}>
                Logout
              </button>
              <div class="navbar onlymobile">
                <div class="dropdown">
                  {userLogo && (
                    <button
                      style={{
                        padding: 0,
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      id="profile"
                    >
                      <img src={userLogo} width={40} height={40} />
                    </button>
                  )}
                  {!userLogo && (
                    <button id="profile">{user?.firstname?.[0]}</button>
                  )}
                  <div class="dropdown-content">
                    <div className="intro">
                      <h5>Hi {user.firstname},</h5>
                      <h5>
                        {user.usertype === "(FFF Pro Trial)"
                          ? "FFF Pro"
                          : "(" + user.usertype + ")"}
                      </h5>
                    </div>
                    <span onClick={() => setdisappearmodal(false)}>
                      Profile
                    </span>
                    <span onClick={() => navigate("/vgdashboard")}>
                      Dashboard
                    </span>
                    <span onClick={logouttapped}>Logout</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <a
          href="https://volatilitygame.com/annual-conference-2025/"
          target="_blank"
          rel="noreferrer"
        >
          Register for 6th FFFP Conference
        </a>
      </div>
    </div>
  );
}

export default Header;
