import { upperCase } from "lodash";
import { useSnackbar } from "react-simple-snackbar";

const useVGSnackBar = () => {
  const options = {
    position: "bottom-right",
    style: {
      backgroundColor: "#f2b42b",
      border: "2px solid #f2b42b",
      color: "black",
      fontSize: "14px",
      textAlign: "center",
      textTransform: "uppercase",
    },
    closeStyle: {
      color: "black",
      fontSize: "16px",
    },
  };

  return useSnackbar(options);
};

export default useVGSnackBar;
