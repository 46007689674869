import React, { useEffect, useRef, useState } from "react";
import "./UserProfile.css";
import db, { auth } from "../Helpers/Firebase";
import useVGSnackBar from "../Helpers/SnackBar";
import Header from "../Helpers/Header";

function UserProfileEditor() {
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [openSnackBar, closeSnackBar] = useVGSnackBar();
  const [modalOpen, setModalOpen] = useState(false);
  const [prospects, setProspects] = useState([]);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const arnRef = useRef(null);
  const companyRef = useRef(null);
  const dobRef = useRef(null);
  const mobileRef = useRef(null);
  const emailRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const websiteRef = useRef(null);
  const linkedinRef = useRef(null);
  const aboutMeRef = useRef(null);
  const practiseInfoRef = useRef(null);
  const productsSolutionsRef = useRef(null);

  const updateTapped = () => {
    const payload = {
      firstname: firstNameRef.current.value,
      lastname: lastNameRef.current.value,
      arn: arnRef.current.value,
      // dob: dobRef.current.value,
      companyname: companyRef.current.value,
      mobile: mobileRef.current.value,
      email: emailRef.current.value,
      city: cityRef.current.value,
      state: stateRef.current.value,
      website: websiteRef.current.value,
      aboutme: aboutMeRef.current.value,
      practiseinformation: practiseInfoRef.current.value,
      productssolutionsoffered: productsSolutionsRef.current.value,
      linkedin: linkedinRef.current.value,
    };
    console.log(payload);
    db.collection("users")
      .doc(userId)
      .update({
        fffArchieve: payload,
      })
      .then((done) => {
        openSnackBar("Data Updated");
      });
  };

  useEffect(() => {
    async function getUser() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          var uid = user.uid;
          setUserId(uid);
          db.collection("users")
            .doc(uid)
            .get()
            .then((userinfo) => {
              db.collection("users")
                .doc(uid)
                .collection("fffarchive")
                .doc("prospects")
                .collection("data")
                .orderBy("createdOn", "desc")
                .get()
                .then((allporps) => {
                  const allProspects = allporps.docs.map((dd) => {
                    return dd.data();
                  });
                  setProspects(allProspects);
                });
              setUser(userinfo.data());
            });
        }
      });
    }
    getUser();
  }, []);

  return (
    <div className="userprofile userprofileeditor">
      <Header />
      {modalOpen && (
        <div className="modal">
          <h4>Your Prospects</h4>
          <div>
            <table>
              <th>
                <td>Name</td>
                <td>Mobile</td>
                <td>Email</td>
                <td>Enquired On</td>
              </th>
              {prospects?.map?.((prospect) => {
                return (
                  <tr>
                    <td>{prospect?.name}</td>
                    <td>{prospect?.mobile}</td>
                    <td>{prospect?.email}</td>
                    <td>
                      {new Date(prospect?.createdOn)
                        ?.toString()
                        ?.split("GMT")?.[0] || ""}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          <button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Close
          </button>
        </div>
      )}
      {user && (
        <div className="intro">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>FFF Archive</h3>
            <div>
              <a
                href={`https://members.volatilitygame.com/userProfile/${userId}_${user.firstname}_${user.lastname}`}
                target="_blank"
                rel="noreferrer"
                id="profilelink"
              >
                View my FFF Archive Profile
              </a>
              <button
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Prospects
              </button>
            </div>
          </div>
          <div className="content">
            <div>
              <h4>First Name</h4>
              <input
                type="text"
                ref={firstNameRef}
                placeholder="Enter First Name"
                defaultValue={user?.fffArchieve?.firstname || user?.firstname}
              />
            </div>
            <div>
              <h4>Last Name</h4>
              <input
                type="text"
                ref={lastNameRef}
                placeholder="Enter Last Name"
                defaultValue={user?.fffArchieve?.lastname || user?.lastname}
              />
            </div>
            <div>
              <h4>ARN</h4>
              <input
                type="text"
                ref={arnRef}
                placeholder="Enter ARN"
                defaultValue={user?.fffArchieve?.arn || user?.arn}
              />
            </div>
            {/* <div>
              <h4>DOB</h4>
              <input
                type="date"
                ref={dobRef}
                placeholder="Enter DOB"
                defaultValue={user?.fffArchieve?.dob || user?.dob}
              />
            </div> */}
            <div>
              <h4>Company Name</h4>
              <input
                type="text"
                ref={companyRef}
                placeholder="Enter Company Name"
                defaultValue={
                  user?.fffArchieve?.companyname || user?.companyname
                }
              />
            </div>
            <div>
              <h4>Mobile</h4>
              <input
                type="text"
                ref={mobileRef}
                placeholder="Enter Mobile No."
                defaultValue={user?.fffArchieve?.mobile || user?.mobile}
              />
            </div>
            <div>
              <h4>Email</h4>
              <input
                type="text"
                ref={emailRef}
                placeholder="Enter Email"
                defaultValue={user?.fffArchieve?.email || user?.email}
              />
            </div>
            <div>
              <h4>City</h4>
              <input
                type="text"
                ref={cityRef}
                placeholder="Enter City"
                defaultValue={user?.fffArchieve?.city || user?.city}
              />
            </div>
            <div>
              <h4>State</h4>
              <input
                type="text"
                ref={stateRef}
                placeholder="Enter State"
                defaultValue={user?.fffArchieve?.state || user?.state}
              />
            </div>
            <div>
              <h4>Website</h4>
              <input
                type="text"
                ref={websiteRef}
                placeholder="Enter Website"
                defaultValue={user?.fffArchieve?.website || user?.website}
              />
            </div>
            <div>
              <h4>Linkedin Profile</h4>
              <input
                type="text"
                ref={linkedinRef}
                placeholder="Enter Linkedin Profile"
                defaultValue={user?.fffArchieve?.linkedin || user?.linkedin}
              />
            </div>

            <div>
              <h4>About Me</h4>
              <textarea
                type="text"
                ref={aboutMeRef}
                placeholder="About Me"
                defaultValue={user?.fffArchieve?.aboutme || user?.aboutme}
              />
            </div>

            <div>
              <h4>Practise Information</h4>
              <textarea
                type="text"
                ref={practiseInfoRef}
                placeholder="Practise Information"
                defaultValue={
                  user?.fffArchieve?.practiseinformation ||
                  user?.practiseinformation
                }
              />
            </div>
            <div>
              <h4>Products & Solutions Offered</h4>
              <textarea
                type="text"
                placeholder="Products & Solutions Offered"
                ref={productsSolutionsRef}
                defaultValue={
                  user?.fffArchieve?.productssolutionsoffered ||
                  user?.productssolutionsoffered
                }
              />
            </div>
          </div>
          <button onClick={updateTapped}>Update</button>
        </div>
      )}
    </div>
  );
}

export default UserProfileEditor;
